export class ViewportScrollerService {
  private scrollYBeforeDisableScrolling: number

  disableBodyScroll(): void {
    this.scrollYBeforeDisableScrolling = window.scrollY
    document.documentElement.classList.add("no-scroll")
    document.body.style.top = `-${this.scrollYBeforeDisableScrolling}px`
  }

  enableBodyScroll(): void {
    document.documentElement.classList.remove("no-scroll")
    window.scrollTo(0, this.scrollYBeforeDisableScrolling)
  }
}
