import toast from "react-simple-toasts"

export class ToastService {
  success(text: string): void {
    toast(text, { className: "toast-success" })
  }

  error(text: string): void {
    toast(text, { className: "toast-error" })
  }
}
