import { useLocale } from "../use-locale/use-locale"
import { Link, navigate } from "gatsby"
import * as React from "react"

export function getLocalizedUrl(url: string, locale?: string): string {
  const lang = locale || useLocale().locale
  return !lang || lang === "uk" ? url : `/${lang}${url}`
}

export function removeTrailingSlash(str: string): string {
  return str.replace(/\/$/, "")
}

export function LocalizedLink(props: {
  to: string
  className?: string
  activeClassName?: string
  children?: any
  partiallyActive?: boolean
  target?: string
  realUrl?: string
}) {
  const { locale } = useLocale()
  const localizedUrl = getLocalizedUrl(props.to, locale)

  if (props.target) {
    return (
      <Link
        className={props.className}
        partiallyActive={props.partiallyActive ?? true}
        activeClassName={props.activeClassName}
        to={localizedUrl}
        target={props.target}
        onClick={e => {
          e.preventDefault()
          window.open(location.origin + localizedUrl)
        }}
      >
        {props.children}
      </Link>
    )
  } else {
    return (
      <Link
        className={props.className}
        partiallyActive={props.partiallyActive ?? true}
        activeClassName={props.activeClassName}
        to={localizedUrl}
        onClick={e => {
          if (props.realUrl) {
            e.preventDefault()
            navigate(props.realUrl)
          }
        }}
      >
        {props.children}
      </Link>
    )
  }
}
