import * as React from "react"
import * as css from "./footer.module.scss"
import { Icon } from "../icon/icon"
import { SocialNetworkUrl } from "../../constants/social-network-url"
import { useTranslation } from "../use-translations/use-translations"

export function Footer() {
  const _ = useTranslation()
  return (
    <footer className={css.footer}>
      <div className="container">
        <div className={css.grid}>
          <div className={css.location}>
            <Icon className={css.icon} name="location" /> {_.footer_address}
          </div>
          <div className={css.social}>
            <a
              className={css.link}
              href={SocialNetworkUrl.Facebook}
              target="_blank"
            >
              <Icon name="facebook" />
            </a>
            <a
              className={css.link}
              href={SocialNetworkUrl.Instagram}
              target="_blank"
            >
              <Icon name="instagram" />
            </a>
            <a
              className={css.link}
              href={SocialNetworkUrl.LinkedIn}
              target="_blank"
            >
              <Icon name="linkedin" />
            </a>
            <a
              className={css.link}
              href={SocialNetworkUrl.TikTok}
              target="_blank"
            >
              <Icon name="tiktok" />
            </a>
          </div>
          <div className={css.copyright}>
            Copyright © {new Date().getFullYear()} • OBRIO
          </div>
        </div>
      </div>
    </footer>
  )
}
