import * as React from "react"
import { useEffect, useState } from "react"
import * as css from "./icon.module.scss"
import { iconService } from "../../constants/di-container"

export function Icon({
  name,
  className = "",
  onClick = () => {},
}: {
  name: string
  className?: string
  onClick?: (e) => any
}) {
  const [icon, setIcon] = useState("")

  useEffect(() => {
    iconService.get(name).subscribe(svg => setIcon(svg))
  }, [name])

  return (
    <i
      className={`${css.icon} ${className}`}
      onClick={e => onClick(e)}
      dangerouslySetInnerHTML={{ __html: icon }}
    />
  )
}
