import * as React from "react"
import { Header } from "../header/header"
import "swiper/scss"
import { Footer } from "../footer/footer"
import { LocaleProvider, useLocale } from "../use-locale/use-locale"
import { useEffect } from "react"

export function CommonLayout({ children, pageContext: { locale, isDefault } }) {
  const { changeLocale } = useLocale()

  useEffect(() => {
    changeLocale(locale)
  }, [locale])

  return (
    <LocaleProvider>
      <Header></Header>
      {children}
      <Footer></Footer>
    </LocaleProvider>
  )
}
