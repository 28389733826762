module.exports = {
  en: {
    path: "en",
    locale: "English",
  },
  uk: {
    path: "uk",
    locale: "Українська",
    default: true,
  },
}
