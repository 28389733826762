import { getLocalizedUrl, LocalizedLink } from "../../localized-link/localized-link"
import { useTranslation } from "../../use-translations/use-translations"
import * as css from "./header-nav.module.scss"
import * as React from "react"
import { useLocale } from "../../use-locale/use-locale"

export function HeaderNav() {
  const { locale } = useLocale();
  const { about_us, products, career, vacancies, media, home } =
    useTranslation()

  return (
    <nav className={css.nav}>
      <LocalizedLink
        activeClassName={css.active}
        partiallyActive={false}
        to="/"
      >
        {home}
      </LocalizedLink>
      <LocalizedLink activeClassName={css.active} to="/about">
        {about_us}
      </LocalizedLink>
      <LocalizedLink activeClassName={css.active} to="/products">
        {products}
      </LocalizedLink>
      <LocalizedLink
        activeClassName={css.active}
        to="/career"
        realUrl={getLocalizedUrl('/career/benefits', locale)}
      >
        {career}
      </LocalizedLink>
      <LocalizedLink activeClassName={css.active} to="/vacancies">
        {vacancies}
      </LocalizedLink>
      <LocalizedLink activeClassName={css.active} to="/media">
        {media}
      </LocalizedLink>
    </nav>
  )
}
