import * as React from "react"
import { Helmet } from "react-helmet"

export function Head() {
  return (
    <Helmet>
      <title>Obrio</title>
    </Helmet>
  )
}
