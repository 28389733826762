import * as React from "react"
import * as css from "./language-selector.module.scss"
import { Link } from "gatsby"
import { useLocale } from "../../use-locale/use-locale"
import classNames from "classnames"
import { getLocalizedUrl } from "../../localized-link/localized-link"

const isActive = (lang: string): boolean => {
  const { locale } = useLocale()

  return locale === lang
}

export function LanguageSelector() {
  const { locale } = useLocale()
  const currentPath =
    typeof location !== "undefined"
      ? location?.pathname?.replace("/" + locale, "")
      : ""

  return (
    <div className={css.selector}>
      <Link
        className={classNames(isActive("uk") ? css.active : "")}
        to={getLocalizedUrl(currentPath, "uk")}
      >
        UA
      </Link>
      <Link
        className={classNames(isActive("en") ? css.active : "")}
        to={getLocalizedUrl(currentPath, "en")}
      >
        En
      </Link>
    </div>
  )
}
