import { Observable } from "rxjs"
import { ajax, AjaxResponse } from "rxjs/ajax"

export class HttpClient {
  constructor(private host: string, private withCredentials = true) {}

  post<T>(url: string, body: any = {}): Observable<AjaxResponse<T>> {
    return ajax<T>({
      url: this.host + url,
      withCredentials: this.withCredentials,
      body,
      method: "POST",
    })
  }
}
