import { useStaticQuery, graphql } from "gatsby"
import { useLocale } from "../use-locale/use-locale"

const query = graphql`
  query useTranslations {
    allFile(filter: { relativeDirectory: { eq: "translations" } }) {
      edges {
        node {
          name
          childrenTranslationsJson {
            about_us
            products
            career
            vacancies
            media
            home
            footer_address
            home_h1
            home_obrio_description
            we_create_products
            mln
            ths
            feature_growth
            feature_installs
            feature_localizations
            feature_rating
            partners_subtitle
            partners_title
            partners_description
            home_media_title
            team
            home_team_title
            home_team_description
            our_team
            find_vacancy
            home_join_us_title
            join_us_cta
            our_products
            nebula_description
            nebula_feature_1
            nebula_feature_2
            factory_description
            asknebula_description
            site
            about_h1
            about_description
            our_history
            serhii_matveev
            alex_fedorov
            alex_fedorov_t
            alex_fedorov_d
            kateryna_romanenko
            kateryna_romanenko_t
            kateryna_romanenko_d
            serhii_matsur
            serhii_matsur_t
            serhii_matsur_d
            maria_yovsa
            maria_yovsa_t
            maria_yovsa_d
            lidiia_korzun
            lidiia_korzun_t
            lidiia_korzun_d
            lesya_nishchenko
            lesya_nishchenko_t
            lesya_nishchenko_d
            khrystyna_savchuk
            khrystyna_savchuk_t
            khrystyna_savchuk_d
            alex_cholovsky
            alex_cholovsky_t
            alex_cholovsky_d
            alex_lychak
            our_values
            where_team_from
            june
            april
            april_2019_t
            april_2019_d
            may
            may_2019_t
            may_2019_d
            may_2020_t
            may_2020_d
            may_2021_t
            june
            june_2020_t
            june_2020_d
            july
            july_2020_t
            november
            november_2020_t
            november_2020_d
            march
            december
            march_2021_t
            march_2021_d
            december_2021_t
            april_2022_t
            april_2022_d
            join_obrio
            want_with_you
            still_hesitating
            join_obrio_d
            technologies
            benefits
            success_stories
            growth_opportunities
            benefit_1_t
            benefit_1_feature_1_t
            benefit_1_feature_1_d
            benefit_1_feature_2_t
            benefit_1_feature_2_d
            benefit_1_feature_3_t
            benefit_1_feature_3_d
            benefit_1_feature_4_t
            benefit_1_feature_4_d
            benefit_2_t
            benefit_2_feature_1_t
            benefit_2_feature_1_d
            benefit_2_feature_2_t
            benefit_2_feature_2_d
            benefit_2_feature_3_t
            benefit_2_feature_3_d
            benefit_2_feature_4_t
            benefit_2_feature_4_d
            benefit_2_feature_5_t
            benefit_2_feature_5_d
            benefit_3_t
            benefit_3_feature_1_t
            benefit_3_feature_1_d
            benefit_3_feature_2_t
            benefit_3_feature_2_d
            benefit_3_feature_3_t
            benefit_3_feature_3_d
            benefit_4_t
            benefit_4_feature_1_t
            benefit_4_feature_1_d
            benefit_4_feature_2_t
            benefit_4_feature_2_d
            benefit_4_feature_3_t
            benefit_4_feature_3_d
            benefit_4_feature_4_t
            benefit_4_feature_4_d
            benefit_5_t
            benefit_5_feature_1_t
            benefit_5_feature_1_d
            benefit_5_feature_2_t
            benefit_5_feature_2_d
            benefit_5_feature_3_t
            benefit_5_feature_3_d
            benefit_5_feature_4_t
            benefit_5_feature_4_d
            benefit_5_feature_5_t
            benefit_5_feature_5_d
            victor_antonenko
            victor_antonenko_story
            serhii_matveev_story
            maks_lukominskii
            maks_lukominskii_story
            vertical_growth
            horizontal_growth
            crossfunctional_growth
            lead_d
            senior_d
            middle_d
            junior_d
            our_vacancies
            other_vacancies
            hiring_process_t
            hiring_process_d
            day
            days
            days_2
            day_1_t
            day_1_d
            day_1_1_t
            day_1_1_d
            days_2_3_t
            days_2_3_d
            days_1_2_t
            days_1_2_d
            days_0_t
            days_0_d
            days_0_0_t
            days_0_0_d
            recommendation_t
            recommendation_d
            write_us
            telegram
            solomia_dmitruk
            we_at_media
            want_to_contact
            contact_us
            alina_neverova
            anton_vodolazky
            anton_vodolazky_story
            dmytro_avramenko
            dmytro_avramenko_story
            ruslan_tron
            ruslan_tron_story
            our_collaborations
            alex_fedorov_media
            ihor_kopiov
            ihor_kopiov_media
            alex_fedorov_media_2
            ihor_kopiov_media_2
            ihor_kopiov_media_3
            andrew_bodnar
            andrew_bodnar_media
            maria_yovsa_media
            maks_lukominskii_media
            kate_petuhova
            kate_petuhova_media
            marketer
            evgenia_shumitskaya
            evgenia_shumitskaya_media
            solomia_dmitruk_media
            nikita_machehin
            nikita_machehin_media
            alina_neverova_media
            alex_fedorov_media_3
            yuri_kovalenko
            yuri_kovalenko_media
            alex_rumantsev
            alex_rumantsev_media
            oleksii_rumiantsev
            oleksii_rumiantsev_story
            victor_antonenko_media_2
            anna_khoma_media
            alex_fedorov_media_4
            victor_antonenko_media_3
            december_2021_d
            anna_khoma_media_2
            viktoria_tymchenko_ivanishina
            viktoria_tymchenko_ivanishina_media
            nebula_description_products
            factory_description_products
            asknebula_description_products
            read_more
            help_text
            help_ukraine_media
            OBRIO
            validation_required
            validation_format
            toast_contact_soon
            toast_something_wrong
            form_first_last_name
            form_position
            form_social_link
            form_cv_format
            form_submit
            obrio_pid_chas_vijny
            kompanii_s_chetkoy_poziciey
            natalia_elenivska
            poverbanki_dlya_zsu
            freed_title
            freed_description
            smule_title
            smule_description
            clear
          }
        }
      }
    }
  }
`

// This hook simplifies query response for current language.
export const useTranslation = () => {
  const { locale } = useLocale()
  const { allFile } = useStaticQuery(query)

  // Extract all lists from GraphQL query response
  const queryList = allFile.edges.map(item => {
    const currentFileTitle = Object.keys(item.node).filter(
      item => item !== "name"
    )[0]

    return {
      name: item.node.name,
      ...item.node[currentFileTitle][0],
    }
  })

  // Return translation for the current locale
  return queryList.filter(lang => lang.name === locale)[0]
}
