import { IconService } from "../common/icon/icon.service"
import { HttpClient } from "../common/http-client/http-client"
import { ToastService } from "../common/toast/toast.service"
import { ViewportScrollerService } from "../common/viewport-scroller/viewport-scroller.service"

export const iconService = new IconService()
export const httpClientService = new HttpClient(
  process.env.GATSBY_API_HOST,
  true
)
export const toastService = new ToastService()
export const scrollerService = new ViewportScrollerService()
