import * as React from "react"
import { Head } from "../head/head"
import * as css from "./header.module.scss"
import { HeaderNav } from "./header-nav/header-nav"
import { useEffect, useState } from "react"
import classNames from "classnames"
import { LanguageSelector } from "./language-selector/language-selector"
import { LocalizedLink } from "../localized-link/localized-link"
import { Icon } from "../icon/icon"
import { SocialNetworkUrl } from "../../constants/social-network-url"
import { useTranslation } from "../use-translations/use-translations"

export function Header() {
  const [sticky, setSticky] = useState(false)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const _ = useTranslation()

  useEffect(() => {
    document.addEventListener("scroll", () => setSticky(window.scrollY > 10), {
      passive: true,
      capture: true,
    })
  })

  return (
    <>
      <Head />
      <div className={css.helpLineSpace} />
      <div className={css.helpLine}>
        <div
          className="container"
          dangerouslySetInnerHTML={{ __html: _.help_text }}
        />
      </div>
      <header
        className={classNames(
          css.header,
          sticky ? css.sticky : "",
          mobileMenuOpen && css.headerMenuOpen
        )}
      >
        <div className="container">
          <div className={css.headerContent}>
            <LocalizedLink to="/">
              <img src={"/logo.svg"} alt="OBRIO Logo" />
            </LocalizedLink>

            <div
              className={classNames(
                css.headerNavigation,
                mobileMenuOpen && css.headerNavigationOpen
              )}
            >
              <HeaderNav />
              <LanguageSelector />

              <div className={css.social}>
                <a
                  className={css.link}
                  href={SocialNetworkUrl.Facebook}
                  target="_blank"
                >
                  <Icon name="facebook" />
                </a>
                <a
                  className={css.link}
                  href={SocialNetworkUrl.Instagram}
                  target="_blank"
                >
                  <Icon name="instagram" />
                </a>
                <a
                  className={css.link}
                  href={SocialNetworkUrl.LinkedIn}
                  target="_blank"
                >
                  <Icon name="linkedin" />
                </a>
                <a
                  className={css.link}
                  href={SocialNetworkUrl.TikTok}
                  target="_blank"
                >
                  <Icon name="tiktok" />
                </a>
              </div>
            </div>

            <span
              className={css.headerMenu}
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              {mobileMenuOpen ? <Icon name="close" /> : <Icon name="menu" />}
            </span>
          </div>
        </div>
      </header>
    </>
  )
}
