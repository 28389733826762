import { from, Observable, ReplaySubject, shareReplay } from "rxjs"

export class IconService {
  private iconsStorage: Map<string, Observable<string>> = new Map<
    string,
    ReplaySubject<string>
  >()

  get(name: string): Observable<string> {
    if (!this.has(name)) {
      const subject = from(
        fetch(`/icons/${name}.svg`)
          .then(response => response.text())
          .then(svg => this.prepareIcon(svg))
      ).pipe(shareReplay(1))

      this.set(name, subject)
    }

    return this.iconsStorage.get(name)
  }

  private set(name: string, icon: Observable<string>): void {
    this.iconsStorage.set(name, icon)
  }

  private has(name: string): boolean {
    return this.iconsStorage.has(name)
  }

  private prepareIcon(icon: string): string {
    return icon
      .replace(/fill="(.*?)"/gi, `fill="currentColor"`)
      .replace(/stroke="(.*?)"/gi, `stroke="currentColor"`)
  }
}
